import { Controller } from "@hotwired/stimulus"
import Intercom from '@intercom/messenger-js-sdk';

export default class extends Controller {
  initialize() {
    Intercom({
      app_id: 'vkf2ggfz',
      user_id: this.element.dataset.user_id,
      name: this.element.dataset.name,
      email: this.element.dataset.email,
      created_at: this.element.dataset.created_at, // IMPORTANT: Replace "user.createdAt" with the variable you use to capture the user's sign-up date in a Unix timestamp (in seconds) e.g. 1704067200
    });
  }
}